import { Options } from "$fresh/plugins/twind.ts";

export default {
  selfURL: import.meta.url,
  theme: {
    extend: {
      colors: {
        primary: "var(--color-primary)",
        secondary: "var(--color-secondary)",
        accent: "var(--color-accent)",
        background: "var(--color-background)",
        content: "var(--color-text)", // Custom text color that uses our CSS variable
      },
      textColor: {
        DEFAULT: "var(--color-text)", // This makes the default text color use our variable
      },
      backgroundColor: {
        DEFAULT: "var(--color-background)", // This makes the default background color use our variable
      },
    },
  },
} as Options;
